
@font-face
{
    font-family: "Pop Warner";
    src: url("../public/pop warner.ttf");
}

@font-face
{
    font-family: "New Athletic M54";
    src: url("../public/new athletic m54.ttf");
}

body
{
    /*background-color: #2d374c;*/
    background-color: #212528;
}

#app
{
    font-family: "New Athletic M54";
    font-size: 122px;
    padding: 0 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home-tab-pane
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tab-bar
{
    /*background-color: #dfe0e0;*/
}

.tab-bar button
{
    color: #5f6363;
}
.tab-bar button.Mui-selected
{
    color: #dfe0e0;
}
.logo-center
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
.count-down
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 900px;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
}

.count-down img
{
    transform: scale(0.65) translate(0px, -90px);
}

.info-table
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-table .MuiPaper-root
{
    width: 900px;
    background-color: #2A2D30;
    margin-top: 30px;
}

.info-table .info-cell
{
    color: #D0D1D2;
    border-bottom: 1px solid #373b3f;
}

.info-table-header .info-cell
{
    color: #D0D1D2;
}

.info-table .info-cell.icon div:first-child
{
    display: flex;
    align-items: center;
    justify-content: left;
}

.info-table .info-cell.icon.discord svg
{
    color: #5562EB;
}

.info-table .info-cell.icon.gold svg
{
    color: #ffc401;
}

.info-table .info-cell.icon svg
{
    color: #503DE4;
}

.info-table .info-cell.icon div:last-child
{
    margin-left: 20px;
}

.register-now-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    margin: 30px 0;
    flex-direction: column;
}

a.register-button
{
    background-color: #513FE3;
    border-radius: 10px;
}

.register-now-container .register-now-label, .rules-tab-pane .register-now-label
{
    color: #dfe0e0;
    margin-bottom: 30px;
    font-family: "Pop Warner";
    letter-spacing: 3px;
    text-transform: uppercase;
}

a.register-button:hover
{
    background-color: rgba(83, 64, 227, 0.8);
}

.rule-icon
{
    display: flex;
    align-items: center;

}
.rule-icon svg
{
    color: rgb(244, 67, 54);
    margin-right: 15px;
}

.rule-icon.green svg
{
    color: rgb(102, 187, 106);
}

.footer
{
    min-height: 200px;
}

.rules-tab-pane .info-cell
{
    text-align: left;
}

.rules-description-label
{
    width: 900px;
    color: #dfe0e0;
}

.rules-tab-pane .register-now-label svg
{
    font-size: 48px;
    margin-right: 20px;
    color: #513FE3;
}

.rules-tab-pane .register-now-label
{
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.rules-tab-pane .rules-description-label b
{
    color: rgb(244, 67, 54);
    text-transform: uppercase;
}

.rules-tab-pane.info-table
{
    flex-direction: column;
}

@media (min-width:320px)
{
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    #app
    {
        padding: 0 10px;
    }
    .rules-description-label
    {
        width: 100%;
    }
    .info-table .MuiPaper-root
    {
        width: 100%;
    }
    .count-down
    {
        width: 95vw;
        height: 200px;
    }
    .count-down img
    {
        transform: scale(0.3) translate(0px, -90px);
    }

    .info-table table
    {
        min-width: unset;
        width: 100%;
    }
    .register-now-container .register-now-label, .rules-tab-pane .register-now-label
    {
        text-align: center;
    }
    .info-table .info-cell.icon div:last-child
    {
        margin-left: 0;
    }
    .info-table .info-cell.icon svg
    {
        margin-right: 20px;
    }
    .logo-center
    {
        margin-bottom: 0px;
    }
}
@media (min-width:410px)
{
    /* smartphones, Android phones, landscape iPhone */
    .rules-description-label
    {
        width: 400px;
    }
    .info-table .MuiPaper-root
    {
        width: 400px;
    }
    .count-down
    {
        width: 400px;
        height: 200px;
    }
    .count-down img
    {
        transform: scale(0.35) translate(0px, -90px);
    }
}



@media (min-width:600px)
{
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .rules-description-label
    {
        width: 550px;
    }
    .info-table .MuiPaper-root
    {
        width: 550px;
    }
    .count-down
    {
        width: 550px;
        height: 200px;
    }
    .count-down img
    {
        transform: scale(0.35) translate(0px, -90px);
    }
}
@media (min-width:801px)
{
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    #app
    {
        padding: 0 100px;
    }
    .rules-description-label
    {
        width: 700px;
    }
    .info-table .MuiPaper-root
    {
        width: 700px;
    }
    .count-down
    {
        width: 700px;
        height: 400px;
    }
    .count-down img
    {
        transform: scale(0.65) translate(0px, -90px);
    }

    .info-table
    {
        width: 700px;
    }

    .info-table table
    {
        min-width: unset;
        width: 100%;
    }
    .register-now-container .register-now-label, .rules-tab-pane .register-now-label
    {
        text-align: center;
    }
    .info-table .info-cell.icon div:last-child
    {
        margin-left: 0;
    }
    .info-table .info-cell.icon svg
    {
        margin-right: 20px;
    }
    .logo-center
    {
        margin-bottom: 0px;
    }
}
@media (min-width:1000px)
{
    /* big landscape tablets, laptops, and desktops */
    #app
    {
        padding: 0 10px;
    }
    .rules-description-label
    {
        width: 900px;
    }
    .info-table .MuiPaper-root
    {
        width: 900px;
    }
    .count-down
    {
        width: 900px;
        height: 400px;
    }
    .count-down img
    {
        transform: scale(0.65) translate(0px, -90px);
    }

    .info-table
    {
        width: 900px;
    }

    .info-table table
    {
        min-width: unset;
        width: 100%;
    }
    .register-now-container .register-now-label, .rules-tab-pane .register-now-label
    {
        text-align: center;
    }
    .info-table .info-cell.icon div:last-child
    {
        margin-left: 0;
    }
    .info-table .info-cell.icon svg
    {
        margin-right: 20px;
    }
    .logo-center
    {
        margin-bottom: 0px;
    }
}
@media (min-width:1281px)
{
    /* hi-res laptops and desktops */
    #app
    {
        padding: 0 10px;
    }
    .rules-description-label
    {
        width: 900px;
    }
    .info-table .MuiPaper-root
    {
        width: 900px;
    }
    .count-down
    {
        width: 900px;
        height: 400px;
    }
    .count-down img
    {
        transform: scale(0.65) translate(0px, -90px);
    }

    .info-table table
    {
        min-width: unset;
        width: 100%;
    }
    .register-now-container .register-now-label, .rules-tab-pane .register-now-label
    {
        text-align: center;
    }
    .info-table .info-cell.icon div:last-child
    {
        margin-left: 0;
    }
    .info-table .info-cell.icon svg
    {
        margin-right: 20px;
    }
    .logo-center
    {
        margin-bottom: 0px;
    }
}





